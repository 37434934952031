import React, {Component} from 'react';
import Task from './Task.jsx';
import {g} from '../../helpers/global.js';
import {DnD} from '../../helpers/DnD.js';
let dnd = null;

export default class TasksView extends Component {
  constructor(props) {
    super(props);
    dnd = new DnD(this.props.cell.tasks);
  }

  duplicateTask = task => {
    let duplicatedTask = g.deepCopy(task);
    let cell = g.deepCopy(this.props.cell);
    duplicatedTask.id = Date.now();
    duplicatedTask.name += ' - copy';
    cell.tasks.push(duplicatedTask);
    this.props.updateCell(cell);
  }

  addTask = () => {
    let newTask = {
      id: Date.now(),
      name: 'New_Task',
      introSlides: [],
      imgs: [],
      videos: [],
      imgsRandom: 0,
      videosRandom: 0,
      clicks: 0,
      exitClick: 0,
      et: 0,
      fc: 0,
      screen: 0,
      audio: 0,
      recalibrate: 0,
      iframe: {
        url: '',
        pasteUserID: false
      }
    }
    let cell = g.deepCopy(this.props.cell);
    cell.tasks.push(newTask);
    this.props.updateCell(cell);
  }

  deleteTask = taskID => {
    let cell = g.deepCopy(this.props.cell);
    let idx = g.findIndexByProp(cell.tasks, 'id', taskID);
    let paths = g.findFilesToDelete('task', cell.tasks[idx]);
    if (this.props.project.id) {
      this.props.fetchProjects().then(projects => {
        let arr = [];
        for (let p of projects) {
          if (p.id == this.props.project.id)
            p = this.props.project;
          arr.push(p);
        }
        let pathsToDelete = g.filterPathsToDelete(paths, arr);
        g.deleteAllMedia(pathsToDelete)
          .finally(() => {
            cell.tasks.splice(idx, 1);
            this.props.updateCell(cell, true);
          })
      });
    } else {
      cell.tasks.splice(idx, 1);
      this.props.updateCell(cell, true);
    }

  }

  updateTask = task => {
    let cell = g.deepCopy(this.props.cell);
    let idx = g.findIndexByProp(cell.tasks, 'id', task.id);
    // let tmp = cell.tasks[idx];
    let isMediaUpdated = false;
    if (cell.tasks[idx].imgs.length !== task.imgs.length || cell.tasks[idx].videos.length !== task.videos.length) {
      isMediaUpdated = true;
    }
    cell.tasks[idx] = task;
    this.props.updateCell(cell, isMediaUpdated);
  }

  updateTasks = tasks => {
    let cell = g.deepCopy(this.props.cell);
    cell.tasks = tasks;
    this.props.updateCell(cell);
  }

  onDragStart = (e, task) => {
    if (dnd && !this.props.cell.active)
      dnd.onDragStart(e, task);
  }

  onDrop = (e, task) => {
    if (!dnd || this.props.cell.active) return;
    dnd.onDrop(e, task);
    let tasks = dnd.getElements();
    this.updateTasks(tasks);
  }

  render() {
    let {cell} = this.props;
    return (
      <>
        <div className="tasks-header">
          <h4> {this.props.cell.name} :: Tasks </h4>
          <button className="btn" onClick={this.addTask} disabled={cell.active}>
            <i className="material-icons">add</i>
            <span>Add</span>
          </button>
          <i className="material-icons teal-text save-project-icon" onClick={this.props.saveProject}>save</i>
        </div>

        <ul className="collapsible expandable task-list">
          {
            cell.tasks.length ?
             cell.tasks
             .map(task => <Task task={task}
                                key={task.id}
                                cell={cell}
                                updateTask={this.updateTask}
                                duplicateTask={this.duplicateTask}
                                deleteTask={this.deleteTask}
                                onDragStart={this.onDragStart}
                                onDragOver={dnd.onDragOver}
                                onDrop={this.onDrop}
                                updated={this.props.updated}
                                projects={this.props.projects}
                                ptIntegration={this.props.project.ptIntegration}
                                />
                      )
               :
             <li className="collapsible-header">
               <span>No tasks in this cell</span>
             </li>
          }
        </ul>
      </>
    )
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.cell.tasks) !== JSON.stringify(this.props.cell.tasks)) {
      dnd = new DnD(this.props.cell.tasks);
    }
  }
}
