import {api} from '../api';

export const g = {
  objectEquals: function (x, y) {
    if (x === null || x === undefined || y === null || y === undefined) {
      return x === y;
    }
    if (x.constructor !== y.constructor) {
      return false;
    }
    if (x instanceof Function) {
      return x === y;
    }
    if (x instanceof RegExp) {
      return x === y;
    }
    if (x === y || x.valueOf() === y.valueOf()) {
      return true;
    }
    if (Array.isArray(x) && x.length !== y.length) {
      return false;
    }
    if (x instanceof Date) {
      return false;
    }
    if (!(x instanceof Object)) {
      return false;
    }
    if (!(y instanceof Object)) {
      return false;
    }
    var p = Object.keys(x);
    return Object.keys(y).every(function(i) {
        return p.indexOf(i) !== -1;
      }) &&
      p.every(function(i) {
        return g.objectEquals(x[i], y[i]);
      });
  },

  findObjByProp (arr, objProp, value) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][objProp] === value)
        return arr[i];
    }
    return null;
  },

  findIndexByProp (arr, objProp, value) {
    if (!arr) return;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][objProp] === value)
        return i;
    }
    return -1;
  },

  validateName (value) {
    let allowed = /^\w*_*$/gi; // allowed are only numbers, letters and _
    return allowed.exec(value);
  },

  deepCopy (obj) {
    return JSON.parse(JSON.stringify(obj));
  },

  filterPathsToDelete (paths, projects)  {
    let toDelete = [];
    for (let path of paths) {
      if (this.canDeleteMedia(path, projects))
        toDelete.push(path);
    }
    return toDelete;
  },

  canDeleteMedia (path, projects) {
    let count = 0;
    for (let project of projects) {
      for (let cell of project.cells) {
        for (let task of cell.tasks) {
          for (let video of task.videos) {
            if (video.path === path) {
              count++;
              if (count > 1) return false;
            }
          }
          for (let img of task.imgs) {
            console.log(img, task.imgs, path)
            if (img.path === path) {
              count++;
              if (count > 1) return false;
            }
          }
        }
      }
    }
    return true;
  },

  deleteAllMedia(paths) {
    return api.deleteAllMedia({paths});
  },

  findAllFiles(project) {
    let files = [];
    project.cells.forEach(cell => {
      files.push(...g.findAllFilesInCell(cell));
    });
    return files;
  },

  findAllFilesInCell(cell) {
    let files = [];
    cell.tasks.forEach(task => {
      files.push(...g.findAllFilesInTask(task));
    });
    return files;
  },

  findAllFilesInTask(task) {
    let files = [];
    task.imgs.forEach(img => files.push(img.path));
    task.videos.forEach(video => files.push(video.path));
    return files;
  },

  findFilesToDelete(entityToDeleteName, entityToDelete) {
    if (entityToDeleteName === 'project') {
      return g.findAllFiles(entityToDelete);
    }
    if (entityToDeleteName === 'cell') {
      return g.findAllFilesInCell(entityToDelete);
    }
    if (entityToDeleteName === 'task') {
      return g.findAllFilesInTask(entityToDelete);
    }
  },

  newProject: {
    name: 'New_Project',
    key: Date.now(),
    active: 0,
    maxComplete: 0,
    cells: [],
    hideSlides: []
  }
}
