import React from 'react';

export default function Switch({isChecked, handleChange, labels}) {
  let setValue = e => {
    handleChange(Number(e.target.checked));
  }

  return (
    <div className="switch">
      <label>
        {labels[0]}
        <input type="checkbox" name="active" checked={isChecked} onChange={setValue}/>
        <span className="lever"></span>
        {labels[1]}
      </label>
    </div>
  )
}
