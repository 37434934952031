import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

let user = localStorage.getItem('user');
if (user) {
  user = JSON.parse(user);
  instance.defaults.params = {};
  instance.defaults.params['userid'] = user.user_id;
}

export default instance
