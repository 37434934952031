import React, { useState } from 'react';
import { g } from '../../helpers/global.js';

export default function NewVideo({ uploadVideo, disabled }) {
  let [video, setVideo] = useState({ name: 'stimulus_name', path: '', file: null, upload: true, id: -1 });

  let setName = e => {
    if (g.validateName(e.target.value))
      setVideo({ ...video, name: e.target.value });
  }


  let updateUpload = e => {
    let videoUpdated = g.deepCopy(video);
    videoUpdated.upload = !video.upload;
    setVideo(videoUpdated);
  };

  let handleUpload = e => {
    if (!e.target.files) return;
    let videoUpdated = { ...video };
    videoUpdated.file = e.target.files[0];
    videoUpdated.id = Date.now();
    setVideo(videoUpdated);
    uploadVideo(videoUpdated);
  }

  return (
    <div className="tasks-newvideo">
      <div className="upload-video">
        <input type="file" accept='video/mp4' onChange={handleUpload} disabled={disabled} />
      </div>

      <div className="input-field">
        <input type="text" value={video.name} onChange={setName} disabled={disabled} />
      </div>

      <label>
        <input type="checkbox" checked={video.upload} className="filled-in" onChange={updateUpload} />
        <span className="active">Upload</span>
      </label>
    </div>
  );
}
