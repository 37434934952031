import {api} from './api';
import {storage} from './helpers/storage.js';
// import {history} from './helpers/history.js';

export const auth = {
 login(user){
   return api.login(user)
     .then(response => {
       user = {...user, userid: response.data.userid, super: response.data.super, user_key: response.data.user_key};
       storage.setItem('user', user);
       return Promise.resolve(user);
     })
     .catch(err => Promise.reject(err.response))
     .finally(() => {
       //history.update()
     })
 },

 logout(){
  api.logout()
    .finally(() => {
      storage.clear();
      //history.update();
      window.location.href = "/";
    });
 }
}
