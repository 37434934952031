import axios from './config.js';

function getToken() {
  return window.localStorage.getItem("t");
}

function getEncodedToken(t) {
  return t ? encodeURIComponent(t) : encodeURIComponent(getToken());
}

export const api = {
  fetchProjects (data) {
    return axios.get(`projects?t=${getEncodedToken()}`);
  },

  saveProject (data) {
    data = {...data, t: getToken()};
    return axios({
      url: 'project/save',
      method: 'POST',
      data
    });
  },

  deleteProject (data) {
    data = {...data, t: getToken()};
    return axios({
      url: 'project/delete',
      method: 'POST',
      data
    });
  },

  uploadMedia (data) {
    data.append('t', getToken());
    return axios({
      url: 'project/file/upload',
      method: 'POST',
      headers: {'Content-type': 'multipart/form-data'},
      data
    });
  },

  deleteMedia (data) {
    data = {...data, t: getToken()};
    return axios({
      url: 'project/file/delete',
      method: 'POST',
      data
    });
  },

  deleteAllMedia (data) {
    data = {...data, t: getToken()};
    return axios({
      url: 'project/files/delete',
      method: 'POST',
      data
    });
  },

  checkUser(params) {
    return axios({
      url: 'user/check',
      params: {t: getEncodedToken(params.t)}
    });
  },

  logout(data) {
    let url = `https://eyesee-hub.com/logout?t=${getEncodedToken()}&app_name=eyesee-platform`;
    window.location.href = url;
  },

  createUser(data) {
    data = {...data, t: getToken()};
    return axios({
      url: 'user/create',
      method: 'POST',
      data
    });
  },

  updateHistory(data) {
    data = {...data, t: getToken()};
    return axios({
      url: 'user/history',
      method: 'POST',
      data
    });
  }
}
