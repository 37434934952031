import {g} from './global.js';

export class DnD {
  constructor(elements) {
    this.elements = elements;
    this.draggingElement = null;
    this.canDnD = false;
  }

  onDragStart = (e, element) => {
    console.log('started dragging')
    e.dataTransfer.setData('data', e.target);
    this.draggingElement = element;
  }

  onDragOver = (e) => {
    console.log(' dragging')
    e.preventDefault();
  }

  onDrop = (e, element) => {
    console.log('cancel dragging')
    e.preventDefault();
    e.dataTransfer.getData("data");
    let i1 = g.findIndexByProp(this.elements, 'id', this.draggingElement.id);
    let i2 = g.findIndexByProp(this.elements, 'id', element.id);
    // if (i1 && i2)
    this.swapElements(i1, i2);
  }

  swapElements = (index1, index2) => {
    let tmp = this.elements[index1];
    this.elements[index1] = this.elements[index2];
    this.elements[index2] = tmp;
  }

  setElements = (elements) => {
    this.elements = elements;
  }

  getElements = () => {
    return this.elements;
  }
}
