import React, {Component} from 'react';
import Cell from './Cell.jsx';
import Link from '../Link.jsx';
import {Materialize} from '../../helpers/Materialize.js';
import {g} from '../../helpers/global.js';

export default class Cells extends Component{
  constructor(props) {
    super(props);
    this.state = {
      link: {
        link: '',
        style: {display: 'none', top: '0px', left: '0px'}
      }
    }
  }

  updateCell = cell => {
    let i = g.findIndexByProp(this.props.project.cells, 'id', cell.id);
    let cells = g.deepCopy(this.props.project.cells);
    cells[i] = cell;
    this.props.updateCells(cells);
  }

  deleteCell = cell => {
    let {project, projects} = this.props;
    let arr = [];

    for (let p of projects) {
      if (p.id === project.id)
        p = project;
      arr.push(p);
    }
    let i = g.findIndexByProp(project.cells, 'id', cell.id);

    if (project.id) {
      let paths = g.findFilesToDelete('cell', project.cells[i]);
      let pathsToDelete = g.filterPathsToDelete(paths, arr);
      if (pathsToDelete.length) {
        g.deleteAllMedia(pathsToDelete)
          .then(response => {
            this.props.saveProject(project, false);
          });
      }
    }
    project.cells.splice(i, 1);
    this.props.updateCells(project.cells);
  }

  duplicateCell = cell => {
    let cells = g.deepCopy(this.props.project.cells);
    let newCell = g.deepCopy(cell);
    newCell.id = Date.now();
    newCell.name += ' - copy';
    cells.push(newCell);
    this.props.updateCells(cells);
  }

  hideLink = () => {
    let link = {
      link: '',
      style: {display: 'none', top: '0px', left: '0px'}
    }
    this.setState({link});
  }

  shouldHideLink = e => {
    if (this.state.link.link && !e.target.getAttribute('islink')) {
      this.hideLink();
    }
  }

  showLink = (e, cellName) => {
    let link = {
      link: `https://api.eyesee-platform.com/front/landing?project=${this.props.project.name}-${cellName}`,
      style: {display: 'flex', top: `${e.clientY + 10}px`, left: `${e.clientX - 400}px`}
    }
    this.setState({link});
  }

  render() {
    return (
      <div className="cells">
        <div className="cells-title">
          <h4>Cells</h4>
          <button className="btn" onClick={this.props.createCell}>
            <i className="material-icons">add</i>
            <span>Add</span>
          </button>
        </div>

        <ul className="collapsible expandable">
          {
            this.props.project.cells.length ?
              this.props.project.cells.map(cell => <Cell key={cell.id}
                                                         cell={cell}
                                                         updateCell={this.updateCell}
                                                         deleteCell={this.deleteCell}
                                                         duplicateCell={this.duplicateCell}
                                                         showLink={this.showLink}
                                                         project={this.props.project.name}
                                                         ptIntegration={this.props.project.ptIntegration}
                                                         cells={this.props.project.cells}
                                                         updateCells={this.props.updateCells}
                                                         languages={this.props.languages}
                                                         />)
               :
              <div className="collapsible-header">No cells in the project yet.</div>
          }
        </ul>

        <Link {...this.state.link} hideLink={this.hideLink}/>
      </div>
    )
  }

  componentDidMount() {
    Materialize.initCollapsibles();
    document.addEventListener('click', this.shouldHideLink);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.shouldHideLink);
  }
}
