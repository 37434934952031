import React, {useEffect} from 'react';
import ExistingImage from './ExistingImage.jsx';
import NewImage from './NewImage.jsx';
import M from 'materialize-css';
import {g} from '../../helpers/global.js';
import {DnD} from '../../helpers/DnD.js';
let dnd = null;

export default function TaskImages ({images, updateImages, uploadMedia, deleteMedia, projectExists, disabled}) {
  useEffect(() => {
    dnd = new DnD(images);
  }, [images.length])

  let addImage = image => {
    let imagesUpdated = g.deepCopy(images);
    imagesUpdated.push(image);
    updateImages(imagesUpdated);
  }

  let uploadImage = image => {
    uploadMedia(image.file)
      .then(path => {
        let newImage = {...image, path};
        delete newImage.file;
        addImage(newImage);
      })
      .catch(err => {})
  }

  let removeImage = image => {
    deleteMedia(image)
      .then(response => {
        let imagesUpdated = g.deepCopy(images);
        let idx = g.findIndexByProp(images, 'id', image.id);
        imagesUpdated.splice(idx, 1);
        updateImages(imagesUpdated);
      })
      .catch(err => M.toast({html: 'Something went wrong, uploading video failed.', classes: 'red', displayLength: 5000}));
  }

  let updateImage = image => {
    let imagesUpdated = g.deepCopy(images);
    let index = g.findIndexByProp(images, 'id', image.id);
    imagesUpdated[index] = image;
    updateImages(imagesUpdated);
  }

  let onDragStart = (e, image) => {
    if (dnd)
    dnd.onDragStart(e, image);
  }

  let onDragOver = (e) => {
    if (dnd)
    dnd.onDragOver(e);
  }

  let onDrop = (e, image) => {
    dnd.onDrop(e, image);
    let imagesUpdated = dnd.getElements();
    updateImages(imagesUpdated);
  }

  return (
    <div className="field media">
      <h6>Images: </h6>
      {
        projectExists ?
        (
          <div className="img-row">
            {
              images.map((image, i) => <ExistingImage key={image.id || i}
                                                      image={image}
                                                      updateImage={updateImage}
                                                      onDragStart={e => onDragStart(e, image)}
                                                      onDragOver={onDragOver}
                                                      onDrop={e => onDrop(e, image)}
                                                      removeImage={() => removeImage(image)}
                                                      disabled={disabled} />)
            }
            <NewImage uploadImage={uploadImage} disabled={disabled}/>
          </div>
        )
          :
        <p> You cannot upload images until the project is saved. </p>
      }
    </div>
  )
}
