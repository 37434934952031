import React from 'react';

export default function TaskHeader({task, duplicateTask, deleteTask, onDragStart, onDragOver, onDrop, cell}) {
  let iClasses = cell.active ? 'material-icons disabled': 'material-icons';

  return (
    <div className="header-wrapper">
      <div className="collapsible-header" draggable onDragStart={e => onDragStart(e, task)} onDragOver={onDragOver} onDrop={e => onDrop(e, task)}>
        <span className="header-title">
          <i className="material-icons">drag_indicator</i>
          <span>{task.name}</span>
        </span>
      </div>
      <div className="m-2 prevent-collapse">
        <i className={iClasses} title="Duplicate Task" onClick={() => duplicateTask(task)}>file_copy</i>
        <i className={iClasses} title="Delete Task" onClick={() => deleteTask(task.id)}>delete</i>
      </div>
    </div>
  )
}
