import React, {useState, useEffect} from 'react';

export default function TaskScreen ({iframe, setIFrame}) {
  let [url, setURL] = useState('');
  let [pasteUserID, setPasteUserID] = useState(false);

  useEffect(() => { // keep this as is because of the backwards compatibility
    if (!iframe) return;
    setURL(iframe.url);
    setPasteUserID(iframe.pasteUserID);
  }, [iframe]);

  let updateFrame = (key, val) => {
    let iframeUpdated = {
      ...iframe,
      [key]: val
    };
    setIFrame(iframeUpdated);
  };

  return (
    <div className="field media screen-capture">
      <h6>Screen capture: </h6>
      <div className="row">
        <div className="input-field col s8">
          <input type="text" name="url" id="url" value={url} onChange={e => updateFrame('url', e.target.value)}/>
          <label className="active" htmlFor="url">URL</label>
        </div>
        <label className="input-field col s4">
          <input id="pasteUserID" name="pasteUserID" type="checkbox" className="filled-in" checked={pasteUserID} onChange={e => updateFrame('pasteUserID', e.target.checked)}/>
          <span htmlFor="pasteUserID">Paste userID</span>
        </label>
      </div>
    </div>
  )
}
