import React, {useState, useEffect} from 'react';
let nullSelected = {project: '', cell: '', task: '', stimulus: ''};

export default function EmoPopup({style, project}) {
  let [cellOptions, setCellOptions] = useState([]);
  let [taskOptions, setTaskOptions] = useState([]);
  let [stimuliOptions, setStimuliOptions] = useState([]);
  let [emoViewerLink, setEmoViewerLink] = useState('#');
  let [selected, setSelected] = useState(nullSelected);
  let [iconClasses, setIconClasses] = useState('material-icons link disabled');

  useEffect(() => {
    let cells = [];
    project.cells.forEach(cell => {
      for (let i = 0; i < cell.tasks.length; i++) {
        if (cell.tasks[i].fc) {
          cells.push({name: cell.name, id: cell.id, tasks: cell.tasks});
          break;
        };
      };
    });
    setCellOptions(cells);
    reset();
    setSelected({...nullSelected, project: project.name});
  }, [project]);

  function reset() {
    setTaskOptions([]);
    setStimuliOptions([]);
    emptyLink();
  }

  function emptyLink() {
    setEmoViewerLink('#');
    setIconClasses('material-icons link disabled');
  }

  let selectCell = e => {
    let value = e.target.value;
    if (!value) {
      setSelected({...selected, cell: '', task: '', stimulus: ''});
      reset();
      return;
    }
    let cell = cellOptions.find(c => c.name === value);
    let tasks = cell.tasks
                .filter(task => task.fc === 1)
                .map(task => ({name: task.name, id: task.id, stimuli: task.imgs.concat(task.videos)}));
    setTaskOptions(tasks);
    setSelected({...selected, cell: cell.name, task: '', stimulus: ''});
  }

  let selectTask = e => {
    let value = e.target.value;
    if (!value) {
      setSelected({...selected, task: '', stimulus:''});
      setStimuliOptions([]);
      emptyLink();
      return;
    }
    let task = taskOptions.find(t => t.name === value);
    setSelected({...selected, task: task.name, stimulus:''});
    let stimuli = task.stimuli
                .map(stimulus => ({name: stimulus.name, id: stimulus.id}));
    setStimuliOptions(stimuli);
  }

  let selectStimulus = e => {
    setSelected({...selected, stimulus: e.target.value});
    if (e.target.value) {
      setEmoViewerLink(`https://emo.eyesee-platform.com?project=${selected.project}&cell=${selected.cell}&task=${selected.task}&stimulus=${e.target.value}`);
      setIconClasses('material-icons link');
    } else {
      emptyLink();
    }
  }

  let copyLink = () => {
    let link = document.getElementById('elink');
    link.select();
    document.execCommand('copy');
  }

  let goToEmoViewer = e => {
    if (emoViewerLink === '#') e.preventDefault();
  }

  return (
    <div className="popup" style={style}>
      <div className="popup-title">
        <label>Links</label>
        <span>
          <i className={iconClasses} title="Copy link" onClick={copyLink}>file_copy</i>
          <a href={emoViewerLink} target="_blank" onClick={goToEmoViewer}>
            <i className={iconClasses} title="Open link">open_in_new</i>
          </a>
        </span>
      </div>

      <div className="popup-body">
        <ul>
          <li>
            <select className="browser-default" placeholder="Choose cell" onChange={selectCell} value={selected.cell}>
              <option value={''}>Choose cell</option>
               {
                 cellOptions.map((cell, i) => <option key={cell.id} value={cell.name}> {cell.name} </option>)
               }
            </select>
          </li>
          <li>
            <select className="browser-default" onChange={selectTask} value={selected.task}>
              <option value={''}>Choose task</option>
              {
                taskOptions.map((task, i) => <option key={task.id} value={task.name}>{task.name}</option>)
              }
            </select>
          </li>
          <li>
            <select className="browser-default" onChange={selectStimulus} value={selected.stimulus}>
              <option value={''}> Choose stimulus </option>
              {
                stimuliOptions.map((stimulus, i) => <option key={stimulus.id} value={stimulus.name}>{stimulus.name}</option>)
              }
            </select>
          </li>
        </ul>
      </div>
      <input type="text" value={emoViewerLink} id="elink"/>
    </div>
  );
}
