import React, {Component} from 'react';
import Cells from './Cells.jsx';
import Slides from './Slides.jsx';
import Switch from '../Switch.jsx';
import Menu from '../Menu.jsx';
import {auth} from '../../auth.js';
import {g} from '../../helpers/global.js';

export default class ProjectView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slides: [
        {id: 1, label: "Setup 1", show: true },
        {id: 2, label: "Setup 2", show: true },
        {id: 3, label: "Setup 3", show: true },
        {id: 4, label: "Setup 4", show: true },
        {id: 5, label: "Calibration intro", show: true },
      ]
    }
  };

  updateProjectName = e => {
    let project = g.deepCopy(this.props.project);
    project.name = e.target.value;
    if (!g.validateName(project.name)) return;
    this.props.updateProject(project);
  }

  updateProjectActive = isActive => {
    let project = g.deepCopy(this.props.project);
    project.active = isActive;
    if (isActive) project.cells.map(cell => cell.active = true);
    this.props.updateProject(project);
  }

  createCell = () => {
    let project = g.deepCopy(this.props.project);

    let newCell = {
      id: Date.now(),
      name: 'New_cell',
      active: 0,
      lang: [],
      tasks: [],
      tasksRandomize: 0,
      cancelLink: '',
      redirectLink: ''
    }

    project.cells.push(newCell);
    this.props.updateProject(project);
  }

  updateCells = cells => {
    let project = g.deepCopy(this.props.project);
    project.cells = cells;
    this.props.updateProject(project);
  }

  updateSlides = (slide, i) => {
    let project = g.deepCopy(this.props.project);
    let slides = g.deepCopy(this.state.slides);
    slides[i] = slide;

    project.hideSlides = project.hideSlides || [];

    if (slide.show) {
      let idx = project.hideSlides.findIndex(s => slide.id === s.id);
      project.hideSlides.splice(idx, 1);
    } else {
      project.hideSlides.push(slide);
    }
    this.props.updateProject(project);
    this.setState({slides})
  }

  saveProject = () => {
    this.props.saveProject(this.props.project);
  }

  setSlides = () => {
    let slides = g.deepCopy(this.state.slides);
    let hideSlides = this.props.project.hideSlides || [];
    hideSlides.forEach((slide, i) => {
      slides.forEach(s => {
        if (s.id === slide.id)
          s.show = slide.show;
      })
    });
    this.setState({slides});
  }

  updatePTIntegration = ptIntegration => {
    let project = g.deepCopy(this.props.project);
    project.ptIntegration = !!ptIntegration;
    this.props.updateProject(project);
  }

  menuItems = [
    {title: 'Log out', action: auth.logout, icon: 'power_settings_new'},
    {title: 'Save Project', action: this.saveProject, icon: 'save'},
    {title: 'Back to Projects', action: this.props.goBack, icon: 'chevron_left'},
    {title: 'Create Cell', action: this.createCell, icon: 'add'}
  ]

  render() {
    let {project, saveProject} = this.props;

    return(
      <div id="project">
        <div className="header">
          <h3 className='title'>{project.name}</h3>
          <i className="material-icons teal-text save-project-icon" onClick={this.saveProject}>save</i>
          <Switch labels={['Inactive', 'Active']}
                  isChecked={project.active}
                  handleChange={this.updateProjectActive}/>
        </div>
        <form className="project-form">
          <div className="input-field name">
            <input type="text" value={project.name} name="name" onChange={this.updateProjectName} disabled={project.active}/>
            <label className="active">Project Name</label>
          </div>

          <div className="input-field">
            <input type="text" value={project.cells.length} disabled name="cells"/>
            <label className="active">Number of cells</label>
         </div>

        <div className="input-field pt-integration">
          <p>PT integration</p>
          <Switch labels={['Off', 'On']}
                  isChecked={project.ptIntegration}
                  handleChange={this.updatePTIntegration}/>
         </div>
       </form>

       <Cells project={this.props.project}
              createCell={this.createCell}
              updateCells={this.updateCells}
              saveProject={saveProject}
              languages={this.props.languages}
              projects={this.props.projects}
              />

        <Slides slides={this.state.slides} updateSlides={this.updateSlides} />

       <Menu items={this.menuItems} />
      </div>
    )
  }

  componentDidUpdate(prevProps) {
    if (prevProps.project !== this.props.project)
      this.setSlides();
  }
}
