import React from 'react';
import Switch from '../Switch.jsx';
import RadioGroup from '../RadioGroup.jsx';
import {g} from '../../helpers/global.js';
import {Link, withRouter} from 'react-router-dom';

function CellBody({cell, updateCell, languages, ...props}) {

  let handleCellChange = (prop, value) => {
    let cellUpdated = g.deepCopy(cell);
    cellUpdated[prop] = value;
    updateCell(cellUpdated);
  }

  let handleCellNameChange = e => {
    let name = e.target.value;
    if (!g.validateName(name)) return;
    // if (name === '') name = "New_Cell";
    handleCellChange('name', name);
  }

  let handleCellActiveChange = isActive => {
    handleCellChange('active', isActive);
  }

  let handleCellMaxCompleteChange = e => {
    if (Number(e.target.value) !== Number(e.target.value) || Number(e.target.value) % 1 !== 0) return;
    handleCellChange('maxComplete', e.target.value);
  }

  let handleOptionsChange = (option, value) => {
    handleCellChange('lang', option)
  }

  return (
    <div className="collapsible-body">
      <form>
        <div className="form-header">
          <div className="input-field name">
           <input type="text" value={cell.name} name="name" onChange={handleCellNameChange} disabled={cell.active}/>
           <label className="active">Name</label>
          </div>

          <div className="input-field max-complete">
            <input type="text" value={cell.maxComplete || 0} name="maxComplete" onChange={handleCellMaxCompleteChange} disabled={cell.active}/>
            <label className="active">Max-complete</label>
          </div>

          <Switch labels={['Inactive', 'Active']}
                  isChecked={cell.active}
                  handleChange={handleCellActiveChange}/>
       </div>

       <RadioGroup title="Languages" options={languages} value={cell.lang} onSelectOption={handleOptionsChange} disabled={cell.active}/>

       <div className="links field">
          <div className="input-field">
           <input type="text" value={cell.cancelLink} name="cancelLink" onChange={e => handleCellChange(e.target.name, e.target.value)} disabled={cell.active}/>
           <label className="active">Cancel Link</label>
          </div>

          <div className="input-field">
            <input type="text" value={cell.redirectLink} name="redirectLink" onChange={e => handleCellChange(e.target.name, e.target.value)} disabled={cell.active}/>
            <label className="active">Redirect Link</label>
          </div>
      </div>

      <div className="cell-tasks field">
        <label className="active">
          <input type="checkbox" className="filled-in" name="tasksRandomize" onChange={e => handleCellChange('tasksRandomize', Number(e.target.checked))} checked={cell.tasksRandomize} disabled={cell.active}/>
          <span>Randomize tasks</span>
        </label>
        <Link to={`/projects/${props.match.params.id}/${cell.id}/tasks`}>
          <button className="btn" >Show tasks</button>
        </Link>
      </div>
     </form>
    </div>
  )
}

export default withRouter(CellBody);
