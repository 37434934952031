import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {g} from '../../helpers/global.js';
import PTValidate from './PTValidate.jsx';

function PTCellBody({cell, updateCell, ...props}) {
  let handleCellChange = (prop, value) => {
    let cellUpdated = g.deepCopy(cell);
    cellUpdated[prop] = value;
    updateCell(cellUpdated);
  }

  let handleCellNameChange = e => {
    let name = e.target.value;
    if (!g.validateName(name)) return;
    handleCellChange('name', name);
  }

  let handleCellMaxCompleteChange = e => {
    if (Number(e.target.value) !== Number(e.target.value) || Number(e.target.value) % 1 !== 0) return;
    handleCellChange('maxComplete', e.target.value);
  }

  return (
    <div className="collapsible-body">
      <form>
        <div className="form-header">
          <div className="input-field name">
           <input type="text" value={cell.name} name="name" onChange={handleCellNameChange} disabled={cell.active}/>
           <label className="active">Name</label>
          </div>

          <div className="input-field max-complete">
            <input type="text" value={cell.maxComplete || 0} name="maxComplete" onChange={handleCellMaxCompleteChange} disabled={cell.active}/>
            <label className="active">Max-complete</label>
          </div>

          <Link to={`/projects/${props.match.params.id}/${cell.id}/tasks`}>
            <button className="btn" >Show tasks</button>
          </Link>
        </div>
      </form>
      <PTValidate cell={cell} project={props.project}/>
    </div>
  )
}

export default withRouter(PTCellBody);
