import React, {useEffect} from 'react';
import NewVideo from './NewVideo.jsx';
import ExistingVideo from './ExistingVideo.jsx';
import M from 'materialize-css';
import {g} from '../../helpers/global.js';
import {DnD} from '../../helpers/DnD.js';
let dnd = null;

export default function TaskVideos({videos, updateVideos, uploadMedia, deleteMedia, projectExists, disabled, isPreloadVideos, setPreloadVideos}) {

  useEffect(() => {
    dnd = new DnD(videos);
  }, [videos])

  let updateVideo = video => {
    let videosUpdated = g.deepCopy(videos);
    let idx = g.findIndexByProp(videos, 'id', video.id);
    videosUpdated[idx] = video;
    updateVideos(videosUpdated);
  }

  let addVideo = newVideo => {
    let videosUpdated = g.deepCopy(videos);
    videosUpdated.push(newVideo);
    updateVideos(videosUpdated);
  }

  let uploadVideo = video => {
    uploadMedia(video.file)
      .then(path => {
        let newVideo = {...video, path};
        delete newVideo.file;
        addVideo(newVideo);
       })
      .catch(err => M.toast({html: 'Something went wrong, uploading video failed.', classes: 'red', displayLength: 5000}));
  }

  let removeVideo = video => {
    deleteMedia(video)
      .then(response => {
        let videosUpdated = g.deepCopy(videos);
        let idx = g.findIndexByProp(videos, 'id', video.id);
        videosUpdated.splice(idx, 1);
        updateVideos(videosUpdated);
      })
      .catch(err => console.log(err));
  }

  let onDragStart = (e, video) => {
    if (dnd && !disabled)
    dnd.onDragStart(e, video);
  }

  let onDragOver = (e) => {
    if (dnd && !disabled)
    dnd.onDragOver(e);
  }

  let onDrop = (e, video) => {
    if (!dnd || disabled) return;
    dnd.onDrop(e, video);
    let videosUpdated = dnd.getElements();
    updateVideos(videosUpdated);
  }

  return (
    <div className="field media videos">
      <div className="videos-title">
        <h6>Videos: </h6>
        <div className="preload-field">
        <label>
        <input type="checkbox" checked={isPreloadVideos} className="filled-in" onChange={e => setPreloadVideos(e.target.checked)}/>
        <span>Preload</span>
        </label>

        </div>
      </div>
      {
        projectExists ?
          (
            <div className="task-videos">
              {
                videos.map((video, i) => <ExistingVideo key={video.id || i}
                                                        video={video}
                                                        removeVideo={removeVideo}
                                                        updateVideo={updateVideo}
                                                        onDragStart={onDragStart}
                                                        onDragOver={onDragOver}
                                                        onDrop={onDrop}
                                                        disabled={disabled} />)
              }
              <NewVideo uploadVideo={uploadVideo} disabled={disabled} />
            </div>
          )
          :
        <p> You cannot upload videos until the project is saved. </p>
      }
    </div>
  )
}
