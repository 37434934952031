import React, {useEffect} from 'react';
import {DnD} from '../../helpers/DnD.js';
import {storage} from '../../helpers/storage.js'

let dnd;

export default function CellHeader({cell, project, deleteCell, duplicateCell, cells, updateCells, showLink}) {
  let iClasses = 'material-icons' + (cell.active ? ' disabled' : '');

  useEffect(() => {
    dnd = new DnD(cells);
  }, [cells]);

  let onDragStart = (e) => {
    if(dnd)
    dnd.onDragStart(e, cell);
  }

  let onDragOver = (e) => {
    dnd.onDragOver(e, cell);
  }

  let onDrop = (e) => {
    dnd.onDrop(e, cell);
    let cellsUpdated = dnd.getElements();
    updateCells(cellsUpdated)
  }

  let onDeleteCell = () => {
    if (cell.active) return;
    deleteCell(cell);
  }

  return (
    <div className="header-wrapper">
      <div className="collapsible-header" draggable onDragStart={onDragStart} onDragOver={onDragOver} onDrop={onDrop}>
       <span className="header-title">
         <i className="material-icons">drag_indicator</i>
         <span>{cell.name}</span>
       </span>
      </div>
      <div className="m-2 prevent-collapse">
        <i className="material-icons" title="Duplicate Cell" onClick={() => duplicateCell(cell)} > file_copy </i>
        <i className={iClasses} title="Delete Cell" onClick={onDeleteCell}> delete </i>
        <i className="material-icons" title="Copy Link" islink="true" onClick={e => showLink(e, cell.name)}> link </i>
        <a href={`${process.env.REACT_APP_STATS_URL}admin/stats/project/${project}-${cell.name}?t=${encodeURIComponent(window.localStorage.getItem('t'))}`} target="_blank">
          <i className="material-icons">equalizer</i>
        </a>
      </div>
    </div>
  )
}
