import React from 'react';
import { g } from '../../helpers/global.js';

export default function ExistingVideo({ video, updateVideo, removeVideo, onDragStart, onDragOver, onDrop, disabled }) {
  let updateName = e => {
    if (!g.validateName(e.target.value)) return;
    let videoUpdated = g.deepCopy(video);
    videoUpdated.name = e.target.value;
    updateVideo(videoUpdated);
  };

  let updateUpload = e => {
    let videoUpdated = g.deepCopy(video);
    videoUpdated.upload = !video.upload;
    updateVideo(videoUpdated);
  };

  let onRemove = e => {
    e.stopPropagation();
    e.preventDefault();
    if (disabled) return;
    removeVideo(video);
  };

  return (
    <div className="tasks-existingvideo" draggable onDragStart={e => onDragStart(e, video)} onDragOver={onDragOver} onDrop={e => onDrop(e, video)}>
      <div className={disabled ? 'chip disabled' : 'chip'}>
        <span> {video.name} </span>
        <i className="material-icons" onClick={onRemove}>close</i>

        <div className="input-field">
          <input type="text" value={video.name} onChange={updateName} disabled={disabled} />
          <label className="active">Name</label>
        </div>

        <label>
          <input type="checkbox" checked={video.upload} className="filled-in" onChange={updateUpload} />
          <span className="active">Upload</span>
        </label>
      </div>
    </div>
  );
}
