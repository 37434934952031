import React, {useEffect} from 'react';
import {Materialize} from '../helpers/Materialize.js';

export default function Menu ({items}) {
  useEffect(() => {
    Materialize.initMenu();
  }, []);

  return (
    <div className="fixed-action-btn active">
      <a className="btn-floating btn-large active">
        <i className="large material-icons">mode_edit</i>
      </a>
      <ul>
        {
        items.map((item, i) => (
          <li title={item.title} onClick={item.action} key={i} style={item.style}>
            <a className="btn-floating">
             <i className="material-icons">{item.icon}</i>
            </a>
          </li>
        ))
        }
      </ul>
    </div>
  )
}
