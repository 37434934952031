import React from 'react';
import { g } from '../../helpers/global.js';

export default function ExistingImage({ image, updateImage, removeImage, onDragStart, onDragOver, onDrop, disabled }) {
  let setName = e => {
    let name = e.target.value;
    if (g.validateName(name))
      updateImage({ ...image, name });
  }

  let setDuration = e => {
    let duration = Number(e.target.value) * 1000;
    if (!duration) duration = 5000;
    updateImage({ ...image, duration });
  }

  let updateUpload = e => {
    let imageUpdated = g.deepCopy(image);
    imageUpdated.upload = !image.upload;
    updateImage({ ...imageUpdated });
  };

  return (
    <div className="task-addmedia" draggable onDragStart={onDragStart} onDragOver={onDragOver} onDrop={onDrop}>
      <button className="btn red lighten-2 waves-light" onClick={removeImage} disabled={disabled}> Remove </button>

      <label className="img-upload">
        <input type="checkbox" checked={image.upload} className="filled-in" onChange={updateUpload} />
        <span className="active">Upload</span>
      </label>

      <div className="input-field img-duration">
        <input type="text" value={parseInt(image.duration) / 1000} onChange={setDuration} disabled={disabled} />
        <span className="stimulus-duration">s</span>
        <label className="active"> Duration </label>
      </div>

      <div className="input-field">
        <input type="text" value={image.name} onChange={setName} disabled={disabled} />
        <label className="active"> Name </label>
      </div>

      <div className="task-previewmedia">
        <img src={image.path} alt="task" />
      </div>
    </div>
  )
}
