import React from 'react';
import {connect} from 'react-redux';
import {hideModal} from '../../redux/actions.js';

function Modal({modal, hideModal}) {
  let modalStyle = modal.isVisible ? {display: 'block'}: {display: 'none'};

  return (
    <div className="app-modal" style={modalStyle}>
      <div className="app-modal-overlay" onClick={() => hideModal()}></div>
      { modal.component }
    </div>
  );
}

const mapStateToProps = state => {
  return {
    modal: state.modal
  }
}

const mapDispatchToProps = dispatch => {
  return {
    hideModal: () => dispatch(hideModal)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
