import React from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {g} from '../../helpers/global.js';

export default function Introslides({introslides, updateIntroslides, disabled}) {
  let addIntroslide = () => {
    let introslidesUpdated = g.deepCopy(introslides);
    let newIntroslide = {id: Date.now(), content: ''};
    introslidesUpdated.push(newIntroslide);
    updateIntroslides(introslidesUpdated);
  }

  let removeIntroslide = i => {
    let introslidesUpdated = g.deepCopy(introslides);
    introslidesUpdated.splice(i, 1);
    updateIntroslides(introslidesUpdated);
  }

  let updateIntroslideContent = (data, index) => {
    let introslidesUpdated = g.deepCopy(introslides);
    introslidesUpdated[index].content = data;
    updateIntroslides(introslidesUpdated);
  }

  let addClasses = 'material-icons teal-text' + (disabled ? ' disabled' : '');
  let removeClasses = disabled ? 'material-icons text-lighten-2 red-text disabled' : 'material-icons text-lighten-2 red-text';

  return (
    <div className="field slides">
      <div className="slide-title">
        <h6>Intro slides: </h6>
        <i className={addClasses} onClick={addIntroslide}>add_circle</i>
     </div>
     {
        introslides.map((introslide, i) => (
          <div className="introslide" key={introslide.id}>
            <div className="icons m-2">
              <i className={removeClasses} title="Remove slide" onClick={() => removeIntroslide(i)}>close</i>
            </div>

            <CKEditor editor={ ClassicEditor }
                      data={introslide.content}
                      disabled={disabled}
                      onChange={ (event, editor) => {
                          const data = editor.getData();
                          updateIntroslideContent(data, i);
                      } }
                          />
          </div>
        ))
     }
    </div>
  )
}
