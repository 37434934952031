import React, {useState, useEffect} from 'react';
import axios from 'axios';

export default function PTValidate({project, cell}) {
  let [servers, setServers] = useState(["EU", "US", "CN"]);
  let [server, setServer] = useState(-1);
  let [noDataOnServer, setNoDataOnServer] = useState(false);
  let [isError, setIsError] = useState(false);
  let [results, setResults] = useState([]);

  let getAPIURL = () => {
    switch (server) {
      case "EU":
        return "https://test.eyesee.be/";
      case "US":
        return "https://eyesee-test.com/";
      case "CN":
        return "https://eyesee-cn.com/";
      default:
        return ""
    }
  }

  let reset = () => {
    setNoDataOnServer(false);
    setIsError(false);
    setResults([]);
  }

  let validate = () => {
    reset();
    let fd = new FormData();
    fd.append("project", project);
    fd.append("cell", cell.name);

    axios.post(`${getAPIURL()}PTIntegration/checkProject.php`, fd, {
      headers: {
        'Authorization': 'Bearer ptintegration'
      }
    }).then(response => {
      let ptData = response.data;
      if (typeof ptData !== "object" && !Array.isArray(ptData)) {
        setNoDataOnServer(true);
        return;
      }
      let tasks = cell.tasks;
      let ptDataMap = {};
      let resultsArr = [];

      ptData.forEach((result, i) => {
        let res = result.split("__");

        if (ptDataMap[res[0]])
          ptDataMap[res[0]].push(res[1]);
        else if (!ptDataMap[res[0]] && res[1])
          ptDataMap[res[0]] = [res[1]];
        else
          resultsArr.unshift({task: res[0], isValid: false})
      });

      tasks.forEach((task, i) => {
        let stimuli = task.videos.map(video => video.name).concat(task.imgs.map(img => img.name)).concat('test');
        if (ptDataMap[task.name]) {
          ptDataMap[task.name].forEach((ptStimuli, i) => {
            if (stimuli.indexOf(ptStimuli) >= 0)
              resultsArr.unshift({task: task.name, stimulus: ptStimuli, isValid: true});
            else
             resultsArr.unshift({task: task.name, stimulus: ptStimuli, isValid: false});
          });
        }
      });

      setResults(resultsArr);
    }).catch(err => setIsError(true));
  }

  let changeServer = e => {
    setServer(e.target.value);
  }

  return (<div className="pt-validate">
    <div className="pt-validate-servers">
      <select name="server" value={server} className="browser-default" onChange={changeServer}>
        <option value="-1" disabled="disabled">Choose server</option>
        {servers.map((server, i) => <option key={i}>{server}</option>)}
      </select>
      <button className="btn" onClick={validate} disabled={server == -1}>Validate</button>
    </div>

    {
      results.length ? (
        <table className="pt-validate-results">
          <thead>
            <tr>
              <th>Task</th>
              <th>Stimulus</th>
            </tr>
          </thead>
          {
            results.map((result, i) => (
              <tr className={result.isValid ? 'green lighten-5' : 'red lighten-5'}>
                <td>{result.task}</td>
                <td>{result.stimulus}</td>
              </tr>
            ))
          }
        </table>
      ) : null
    }

    {
      noDataOnServer ?
      <p className="card-panel blue lighten-5">There is no relevant data on this server.</p>: null
    }

    {
      isError ?
      <p className="card-panel red lighten-5">Sorry, something went wrong.</p>: null
    }
  </div>)
}
