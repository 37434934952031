import React from 'react';
import TaskHeader from './TaskHeader.jsx';
import TaskBody from './TaskBody.jsx';
import PTTaskBody from './PTTaskBody.jsx';

export default function Task({task, duplicateTask, deleteTask, updateTask, cell, onDragStart, onDragOver, onDrop, updated, projects, ptIntegration}) {
  return (
    <li className="task">
      <TaskHeader task={task}
                  duplicateTask={duplicateTask}
                  deleteTask={deleteTask}
                  onDragStart={onDragStart}
                  onDragOver={onDragOver}
                  onDrop={onDrop}
                  cell={cell}
                />
              {
                ptIntegration ?
                <PTTaskBody task={task} updateTask={updateTask} updated={updated} cell={cell} projects={projects}/>
                :
                <TaskBody task={task} updateTask={updateTask} updated={updated} cell={cell} projects={projects}/>
              }
    </li>
  )
}
