import React,{Component} from 'react';
import ProjectView from '../components/project/ProjectView.jsx';
import Navigation from '../components/navigation/Navigation.js';
import M from 'materialize-css';
import {connect} from 'react-redux';
import {fetchProjects, setCurrentProject, saveProject, updateProject, openModal, hideModal} from '../redux/actions.js';
import {storage} from '../helpers/storage.js';

class Project extends Component {
  constructor(props){
    super(props);
    this.id = 0;
    this.state = {
      user: JSON.parse(window.localStorage.getItem('user'))
    }
  }

  setProject = () => {
    this.props.fetchProjects()
      .then(projects => {
        let project = projects.find(project => project.id == this.id);
        storage.setItem('project', JSON.stringify(project));
        this.props.setCurrentProject(project);
      });
  }

  goBack = () => {
    this.props.history.push('/projects');
  }

  saveProject = (project = this.props.project, withToasted = true) => {
    this.props.saveProject(project)
      .then(response => {
        if (withToasted)
          M.toast({html: 'Project saved successfully', classes: 'teal', displayLength: 5000});
      })
      .catch(err => M.toast({html: 'Sorry, something went wrong. Please, try again.', classes: 'red', displayLength: 5000}));
  }

  render() {
    return (
      <div>
        <Navigation links={[]} user={this.state.user} appName="eyesee-platform"/>
        <ProjectView project={this.props.project}
                     projects={this.props.projects}
                     updateProject={this.props.updateProject}
                     saveProject={(project, withToasted) => this.saveProject(project, withToasted)}
                     goBack={this.goBack}
                     languages={this.props.languages}
                   />;
      </div>
    )
  }

  componentDidMount() {
    this.id = this.props.match.params.id;

    if (Number(this.id) != this.id && this.id !== 'create-new' ) {
      this.props.history.push('/projects');
      return;
    }

    if (Number(this.id) == this.id) {
      this.setProject();
    }
  }
}

const mapStateToProps = state => {
  return {
    projects: state.projects,
    project: state.project,
    languages: state.languages
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchProjects: () => dispatch(fetchProjects()),
    setCurrentProject: project => dispatch(setCurrentProject(project)),
    saveProject: project => dispatch(saveProject(project, dispatch)),
    updateProject: project => dispatch(updateProject(project), dispatch),
    openModal: component => dispatch(openModal(component, dispatch)),
    hideModal: () => dispatch(hideModal)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Project);
