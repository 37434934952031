import {api} from '../api';

export const fetchProjects = dispatch => {
  return dispatch => {
    return api.fetchProjects()
      .then(response => {
        if (response.data.status === 400) return Promise.reject();
        let projects = [];
        for (let i = 0; i < response.data.projects.length; i++) {
           let project = JSON.parse(response.data.projects[i].project);
           if (typeof project !== 'object')
             project = JSON.parse(project);
           project.id = response.data.projects[i].id;
           if (!project.key) {
             project.key = Date.now();
           }
           projects.push(project);
        }
        dispatch({type: 'FETCH_PROJECTS', projects, languages: response.data.languages});
        return Promise.resolve(projects);
      })
      .catch(err => Promise.reject(err));
  }
}

export const saveProject = (project, dispatch) => {
  return dispatch => {
    return api.saveProject({project: JSON.stringify(project)})
      .then(response => {
          if (response.data !== 'Updated') {
            project.id = response.data;
            dispatch({type: 'SET_CURRENT_PROJECT', project});
          }
          return Promise.resolve(project);
        })
      .catch(err => Promise.reject(err));
    }
  }

export const addProject = project => {
  return dispatch => dispatch({type: 'ADD_PROJECT', project});
}

const getProjectActivity = project => {
  let isActive = false;
  project.cells.forEach(cell => {
    if (cell.active) isActive = true;
  });
  return isActive;
}

export const updateProject = (project, dispatch) => {
  project.active = getProjectActivity(project);
  return dispatch => {
    dispatch({type: 'UPDATE_PROJECT', project});
  }
}

export const deleteProject = (id, dispatch) => {
  return dispatch => {
    return api.deleteProject({id})
      .then(response => {
          dispatch({type: 'REMOVE_PROJECT', id});
          return Promise.resolve();
        })
      .catch(err => Promise.reject(err));
    }
  }

export const setCurrentProject = (project, dispatch) => {
    return dispatch => dispatch({type: 'SET_CURRENT_PROJECT', project});
}

export const openModal = (component, dispatch) => {
  return dispatch => dispatch({type: 'OPEN_MODAL', component});
}

export const hideModal = dispatch => {
  dispatch({type: 'HIDE_MODAL'});
}

export const setProjectView = (view, dispatch) => {
  return dispatch => dispatch({type: 'SET_PROJECT_VIEW', view});
}

export const searchProject = (value, dispatch) => {
  let searchFor = value.toLowerCase().trim();
  return dispatch({type: "SEARCH_PROJECT", searchFor})
}

export const sortProjects = (sortBy, sortOrder, sortType, dispatch) => {
  return dispatch({type: "SORT_PROJECTS", sortBy, sortOrder})
}
