import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import M from 'materialize-css';
import 'materialize-css/dist/css/materialize.min.css';
import {store} from './redux/store.js';
import {Provider} from 'react-redux';
import * as serviceWorker from './serviceWorker';

M.AutoInit();

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));


serviceWorker.unregister();
