import React, {useEffect} from 'react';

export default function Link({link, style, hideLink}) {
  useEffect(() => {
    let link = document.getElementById('link');
    link.select();
  });

  let copyLink = () => {
    let link = document.getElementById('link');
    link.select();
    document.execCommand('copy');
  }

  return (
    <div className="link-preview" style={style} onClick={e => e.stopPropagation()} islink="true">
      <input type="text" value={link} id="link" onChange={() => {}} islink="true"/>
      <i className="material-icons link" onClick={copyLink} islink="true">file_copy</i>

      <a href={link} target="_blank" islink="true">
        <i className="material-icons link open-link" islink="true">open_in_new</i>
      </a>
    </div>
  )
}
