import React,{Component} from 'react';
import TasksView from '../components/tasks/TasksView.jsx';
import Menu from '../components/Menu.jsx';
import M from 'materialize-css';
import Navigation from '../components/navigation/Navigation.js';
import {auth} from '../auth.js';
import {connect} from 'react-redux';
import {saveProject, updateProject, fetchProjects, openModal, hideModal} from '../redux/actions.js';
import {g} from '../helpers/global.js';
import {storage} from '../helpers/storage.js';
import {Materialize} from '../helpers/Materialize.js';

class Tasks extends Component {
  constructor(props){
    super(props);
    this.state = {
      cell: {tasks: []},
      idx: -1,
      update: false,
      user: JSON.parse(window.localStorage.getItem('user'))
    }
  }

  setCell = () => {
    let cellID = Number(this.props.match.params.cellID);
    let idx = g.findIndexByProp(this.props.project.cells, 'id', cellID);
    // if the page is reloaded
    if (idx === -1) {
      let project = JSON.parse(storage.getItem('project'));
      if (!project) this.props.history.push('/projects/');
      this.props.updateProject(project);
      idx = g.findIndexByProp(project.cells, 'id', cellID);
      let cell = project.cells[idx];
      this.setState({cell,idx, update: !this.state.update});
      return;
    }
    let cell = g.deepCopy(this.props.project.cells[idx]);
    this.setState({cell,idx});
  }

  goBack = () => {
    let id = this.props.project.id || 'create-new';
    this.props.history.push('/projects/' + id);
  }

  saveProject = () => {
    this.props.saveProject(this.props.project)
      .then(response => {
        this.props.updateProject(response);
        this.setState({update: !this.state.update});
        M.toast({html: 'Project saved successfully', classes: 'teal', displayLength: 5000})
      })
      .catch(err => M.toast({html: 'Sorry, something went wrong. Please, try again.', classes: 'red', displayLength: 5000}));
  }

  updateCell = (cell, isMediaUpdated = false) => {
    let project = g.deepCopy(this.props.project);
    project.cells[this.state.idx] = cell;
    this.isMediaUpdated = isMediaUpdated;
    this.setState({cell});
  }

  updateProject = () => {
    let project = g.deepCopy(this.props.project);
    project.cells[this.state.idx] = g.deepCopy(this.state.cell);
    this.props.updateProject(project);
    if (this.isMediaUpdated) {
      this.props.saveProject(project);
      this.isMediaUpdated = false;
    }
  }

  menuItems = [
    {title: 'Log out', action: auth.logout, icon: 'power_settings_new'},
    {title: 'Save Project', action: this.saveProject, icon: 'save'},
    {title: 'Go to Project page', action: this.goBack, icon: 'chevron_left'},
    {title: 'Add New Task', action: this.addTask, icon: 'add'}
  ]

  render() {
    return (
      <>
        <Navigation links={[]} user={this.state.user} appName="eyesee-platform"/>
        <div id="tasks">
          <TasksView cell={this.state.cell}
                     project={this.props.project}
                     updateCell={this.updateCell}
                     saveProject={this.saveProject}
                     goBack={this.goBack}
                     updated={this.state.update}
                     projects={this.props.projects}
                     fetchProjects={this.props.fetchProjects}
                     />

          <Menu items={this.menuItems} />
        </div>
      </>
    )
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.cell.name && JSON.stringify(prevState.cell) !== JSON.stringify(this.state.cell)) {
      this.updateProject();
    }
  }

  componentDidMount() {
    this.setCell();
    this.props.fetchProjects();
    Materialize.initCollapsibles();
  }
}

const mapStateToProps = state => {
  return {
    projects: state.projects,
    project: state.project
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchProjects: () => dispatch(fetchProjects()),
    saveProject: project => dispatch(saveProject(project, dispatch)),
    updateProject: project => dispatch(updateProject(project), dispatch),
    openModal: component => dispatch(openModal(component, dispatch)),
    hideModal: () => dispatch(hideModal)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
