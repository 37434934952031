import React from 'react';
import {Link} from 'react-router-dom';

export default function ProjectRow({project, duplicateProject, onDeleteProject, onEmoViewerPopup}) {
  let statusClass = 'project-status ' + (project.active ? 'green lighten-2': 'red lighten-1');
  return (
    <tr>
      <td className="project-name">
        <Link to={`/projects/${project.id}`}>
          <span>{project.name}</span>
        </Link>
      </td>
      <td>
        <div className={statusClass} title={project.active ? 'Active' : 'Inactive'}></div>
      </td>
      <td className="centered">{project.cells.length}</td>
      <td className="narrow-col">
        {
          <div className={'project-status ' + (project.ptIntegration ? 'green lighten-2': 'red lighten-1')} title={project.ptIntegration ? 'Active' : 'Inactive'}></div>
        }
      </td>
      <td className="centered">0</td>

      <td className="centered">
        <span className="icons m-2">
          <i className="material-icons link" title="Duplicate Project" onClick={duplicateProject}>file_copy</i>
          <i className="material-icons link" title="Delete Project" onClick={onDeleteProject}>delete</i>
          <i className="material-icons link teal-text text-lighten-2 goto" title="Go To EmoViewer" onClick={onEmoViewerPopup}>tag_faces</i>
        </span>
      </td>
    </tr>
  )
}
