import React from 'react';
import CellHeader from './CellHeader.jsx';
import CellBody from './CellBody.jsx';
import PTCellBody from './PTCellBody.jsx';

export default function Cell({project, cell, deleteCell, duplicateCell, updateCell, cells, updateCells, showLink, languages, ptIntegration}) {
  return (
    <li className="cell">
      <CellHeader cell={cell}
                  project={project}
                  deleteCell={deleteCell}
                  duplicateCell={duplicateCell}
                  cells={cells}
                  updateCells={updateCells}
                  showLink={showLink}/>
      {
        ptIntegration ?
        <PTCellBody cell={cell} project={project} updateCell={updateCell} /> :
        <CellBody cell={cell} updateCell={updateCell} languages={languages}/>
      }

    </li>
  )
}
