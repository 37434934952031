import {g} from '../helpers/global.js';

let modal = {
  isVisible: false,
  component: null
}

let initialState = {
  projectsDB: [],
  projects: [],
  filteredProjects: [],
  languages: [],
  project: g.newProject,
  modal,
  view: getInitialProjectView()
}

function sort(list, criteria, order, type) {
  let arr = list.sort((function (a, b) {
    let val1 = type === "number" ? Number(a[criteria]) : ("" + a[criteria]).toUpperCase();
    let val2 = type === "number" ? Number(b[criteria]) : ("" + b[criteria]).toUpperCase();
    let compare1 = order === 'asc' ? val1 : val2;
    let compare2 = order === 'asc' ? val2 : val1;
    if (compare1 < compare2) {
      return -1;
    }
    if (compare1 > compare2) {
      return 1;
    }
    return 0;
  }));
  return arr;
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_PROJECTS':
      return {...state, projectsDB: action.projects, projects: action.projects, filteredProjects: action.projects, languages: action.languages};

    case 'ADD_PROJECT':
      let projects1 = [...state.projects];
      projects1.push(action.project);
      return {...state, projects: projects1, filteredProjects: projects1};

    case 'REMOVE_PROJECT':
      let projects2 = [...state.projects];
      let idx = g.findIndexByProp(projects2, 'id', action.id);
      projects2.splice(idx, 1);
      return {...state, projects: projects2, filteredProjects: projects2};

    case 'UPDATE_PROJECT':
      localStorage.setItem('project', JSON.stringify(action.project));
      return {...state, project: action.project};

    case 'SET_CURRENT_PROJECT':
      localStorage.setItem('project', JSON.stringify(action.project));
      return {...state, project: action.project, projectDB: action.project};

    case 'OPEN_MODAL':
      modal = {isVisible: true, component: action.component};
      return {...state, modal};

    case 'HIDE_MODAL':
      modal = {isVisible: false, component: null};
      return {...state, modal};

    case 'SET_PROJECT_VIEW':
      return {...state, view: action.view};

    case 'SEARCH_PROJECT':
      let projects = [...state.projects];
      return {...state, filteredProjects: projects.filter(project => project.name.toLowerCase().indexOf(action.searchFor) >= 0)};

    case 'SORT_PROJECTS':
      let projects3 = [...state.filteredProjects];
      return {...state, filteredProjects: sort(projects3, action.sortBy, action.sortOrder, action.sortType)};

    default:
      return state;
  }
}

function getInitialProjectView() {
  const urlParams = new URLSearchParams(window.location.search);
  const view = urlParams.get('view');
  return view === 'tasks' ? 'tasks' : 'project';
}
