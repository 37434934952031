import React, {useState} from 'react';
import {g} from '../../helpers/global.js';

export default function TaskOptions({task, updateOption, disabled}) {
  let [options, setOptions] = useState([
    {name: 'fc', label: 'facial coding', checked: task.fc},
    {name: 'et', label: 'eye tracking', checked: task.et},
    {name: 'screen', label: 'screen', checked: task.screen},
    {name: 'audio', label: 'audio', checked: task.audio},
    {name: 'imgsRandom', label: 'images random', checked: task.imgsRandom},
    {name: 'videosRandom', label: 'videos random', checked: task.videosRandom},
    {name: 'clicks', label: 'clicks', checked: task.clicks},
    {name: 'exitClick', label: 'exit click', checked: task.exitClick},
    {name: 'recalibrate', label: 'recalibrate before task', checked: task.recalibrate}
  ]);

  let handleOptionChange = i => {
    let optionsUpdated = g.deepCopy(options);
    let option = optionsUpdated[i];
    option.checked = !option.checked;
    setOptions(optionsUpdated);
    updateOption(option.name, Number(option.checked));
  }

  return (
    <div className="field options">
      <h6>Options: </h6>
      {
        options.map((option, i) => {
          return (
            <label className="option" key={i}>
              <input type="checkbox" name={option.name} className="filled-in" checked={option.checked} onChange={e => handleOptionChange(i)} disabled={disabled}/>
              <span>{option.label}</span>
            </label>
          )
        })
      }
    </div>
  )
}
