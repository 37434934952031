import React, {Component} from 'react';
import {g} from '../../helpers/global.js';

export default class Slides extends Component {
  updateSlide = i => {
    let slides = g.deepCopy(this.props.slides);
    slides[i].show = !slides[i].show;
    this.props.updateSlides(slides[i], i);
  };

  render() {
    return (
      <ul className="collapsible slides">
        <li>
          <div className="collapsible-header slides-title">
            <div className="slides-title-main">
              <i className="material-icons">dvr</i>
              <span>Slides</span>
            </div>
            <div>
              <i className="material-icons">keyboard_arrow_down</i>
            </div>
          </div>

          <div className="collapsible-body">
            <ul className="collection">
              {
                this.props.slides.map((slide, i) => (
                  <li className="collection-item slide" key={i}>
                    <p>{slide.label}</p>

                    <div className="switch">
                      <label>
                        hide
                        <input type="checkbox" checked={slide.show} onChange={() => this.updateSlide(i)}/>
                        <span className="lever"></span>
                        show
                      </label>
                    </div>
                  </li>
                ))
              }
             </ul>
          </div>
        </li>
      </ul>
    )
  };
}
