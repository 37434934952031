import React from 'react';

export default function RadioGroup({title, options, onSelectOption, value , disabled}) {
  return (
    <div className="radio-group">
      <h6> {title} </h6>

      <div className="field radio-options">
        {
          options.map((option, i) => {
            return (
              <label key={i} className="active radio-option">
                <input type="radio" className="with-gap" name="lang" id={option} onChange={e => onSelectOption(option, e.target.checked)} checked={option === value} disabled={disabled}/>
                <span>{option}</span>
              </label>
            )
          })
        }
      </div>
    </div>
  )
}
