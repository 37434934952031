import React, {Component} from 'react';
import Loading from '../components/Loading.jsx';
import ProjectRow from '../components/projects/ProjectRow.jsx';
import Navigation from '../components/navigation/Navigation.js';
import Modal from '../components/modals/Modal.jsx';
import EmoPopup from '../components/modals/EmoPopup.jsx';
import DeleteProject from '../components/modals/DeleteProject.jsx';
import M from 'materialize-css';
import {fetchProjects} from '../redux/actions.js';
import {connect} from 'react-redux';
import {auth} from '../auth.js';
import {storage} from '../helpers/storage.js'
import {g} from '../helpers/global.js';
import {saveProject, addProject, deleteProject, openModal, hideModal, setCurrentProject, searchProject, sortProjects} from '../redux/actions.js';
import {SearchableColumn, SortableColumn} from "../components/ui";
import {api} from '../api/';

class Projects extends Component {
  constructor(props){
    super(props);
    this.state = {
      user: {},
      isAuthenticated: false,
      projects: [],
      isLoading: true,
      sortBy: '',
      sortOrder: 'asc',
      emoViewerData: {cells: []},
      emoViewerStyle: {
        display: 'none',
        top: '0px',
        left: '0px'
      }
    }
  }

  createProject = () => {
    this.props.setCurrentProject(g.newProject);
    this.props.history.push('/projects/create-new');
  }

  duplicateProject = (id) => {
    let project = g.deepCopy(g.findObjByProp(this.state.projects, 'id', id));
    delete project.id;
    project.key = Date.now();
    project.name += ' - copy';
    this.props.saveProject(project)
      .then(response => {
        project.id = response.data;
        this.props.addProject(project);
      })
      .catch(err => M.toast({html: 'Sorry, something went wrong. Please, try again.', classes: 'red', displayLength: 5000}));
  }

  onDeleteProject = id => {
    let component = <DeleteProject hideModal={this.props.hideModal} deleteProject={() => this.deleteProject(id)}/>;
    this.props.openModal(component);
  }

  deleteProject = (id) => {
    let project = g.findObjByProp(this.props.projects, 'id', id);
    let paths =  g.findFilesToDelete('project', project);
    let pathsToDelete = g.filterPathsToDelete(paths, this.props.projects);
    this.props.hideModal();
    this.props.deleteProject(id)
      .then(response => {
        if (pathsToDelete.length)
          g.deleteAllMedia(pathsToDelete);
      })
      .catch(err => M.toast({html: 'Sorry, something went wrong. Please, try again.', classes: 'red', displayLength: 5000}));
  }

  fetchProjects = () => {
    this.props.fetchProjects()
      .then(projects => {
        this.setState({projects, isLoading: false})
      });
  }

  onEmoViewerPopup = (e, project) => {
    e.stopPropagation();
    e.preventDefault();
    let emoViewerStyle = {
      display: 'block',
      left: (e.clientX + 25) + 'px',
      top: (e.clientY - 25)+ 'px'
    }
    this.setState({emoViewerStyle, emoViewerData: project});
  }

  closeEmoViewerPopup = e => {
    if (this.popup || !this.popup.contains(e.target)) {
      let emoViewerStyle = {
        display: 'none',
        left: '0px',
        top: '0px'
      }
      this.setState({emoViewerStyle, emoViewerData: {cells: []}});
    }
  }

  onSort = (sortBy, sortOrder, sortType) => {
    this.setState({sortBy, sortOrder})
    this.props.sortProjects(sortBy, sortOrder, sortType);
  }

  render() {
    return this.state.isAuthenticated ? (
        <>
          <Navigation links={[]} user={this.state.user} appName="eyesee-platform"/>
          <div id="projects">
            <Modal />
            <span ref={node => this.popup = node} onClick={e => {e.stopPropagation()}}>
              <EmoPopup style={this.state.emoViewerStyle} project={this.state.emoViewerData}/>
            </span>
            <div className="title">
              <h3>Projects</h3>
              <div className="title-icons">
                <a href={`${process.env.REACT_APP_STATS_URL}admin/stats/projects?t=${encodeURIComponent(window.localStorage.getItem('t'))}`}
                  title="Statistics"
                  target="_blank">
                    <i className="material-icons link">equalizer</i>
                </a>
                <button className="btn project-title-btn" onClick={this.createProject}>
                  <i className="material-icons">add</i>
                  <span>Add</span>
                </button>
              </div>
            </div>

            <table className="responsive-table highlight striped">
              <thead>
                <tr>
                  <SearchableColumn placeholder="Name" name="name" onSearch={(e) => this.props.searchProject(e.target.value)}/>
                  <SortableColumn title="Status" name="active" onSort={this.onSort} sortBy={this.state.sortBy} sortOrder={this.state.sortOrder}/>
                  <th className="centered">Number of cells</th>
                  <th className="narrow-col">PT integration</th>
                  <th className="centered">Completed</th>
                  <th>Options</th>
                </tr>
              </thead>
              {
                this.state.isLoading ?
                <Loading />
                    :
                <tbody>
                 {
                   this.props.filteredProjects.length ?
                   this.props.filteredProjects.map((project, i) =>  <ProjectRow key={project.id}
                                                                        project={project}
                                                                        onEmoViewerPopup={e => this.onEmoViewerPopup(e, project)}
                                                                        duplicateProject={() => this.duplicateProject(project.id)}
                                                                        onDeleteProject={() => this.onDeleteProject(project.id)} />)
                       :
                   <tr><td colSpan="5">No projects yet.</td></tr>
                  }
                </tbody>
              }
            </table>
          </div>
        </>
        ) : null
  }

  componentWillMount() {
    let t = new URLSearchParams(window.location.search).get('t');
    window.localStorage.setItem('t', t);
    api.checkUser({t})
      .then(response => {
        if (response.data.status === 400) return Promise.reject();
        let user = response.data;
        user.name = user.user_name;
        user.img = `https://eyesee-hub.com/images/employees/${user.user_name.split(' ').join('_')}.jpg`;
        this.setState({isAuthenticated: true, user});
        window.localStorage.setItem('user', JSON.stringify(user))
        window.isAuthenticated = true;
      })
      .catch(() => M.toast({html: 'Wrong credentials!', classes: 'red', displayLength: 5000}));
  }

  componentDidMount() {
    this.fetchProjects();
    window.onclick = e => this.closeEmoViewerPopup(e);
  }

  componentWillUnmount() {
    window.onclick = null;
  }
}

const mapStateToProps = state => {
  return {
    projects: state.projects,
    filteredProjects: state.filteredProjects
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchProjects: () => dispatch(fetchProjects()),
    saveProject: project => dispatch(saveProject(project, dispatch)),
    addProject: project => dispatch(addProject(project, dispatch)),
    deleteProject: id => dispatch(deleteProject(id, dispatch)),
    openModal: component => dispatch(openModal(component, dispatch)),
    hideModal: () => dispatch(hideModal),
    setCurrentProject: project => dispatch(setCurrentProject(project)),
    searchProject: (value) => dispatch(searchProject(value, dispatch)),
    sortProjects: (sortBy, sortOrder, sortType) => dispatch(sortProjects(sortBy, sortOrder, sortType, dispatch)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
