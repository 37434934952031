import React from 'react';
import "./sortable-column.css";

let SortableColumn = ({ title, name, onSort, sortBy, sortOrder, sortType, children }) => {
  return (
    <th>
      <div className="sort-items">
        <div className="sort-title">
          {children || title}
        </div>
        <div className="sort-arrows">
          <i className={"material-icons sort-arrow-up" + (sortBy === name && sortOrder === 'asc' ? ' active' : '')} onClick={() => onSort(name, 'asc', sortType)}>arrow_drop_up</i>
          <i className={"material-icons sort-arrow-down" + (sortBy === name && sortOrder === 'desc' ? ' active' : '')} onClick={() => onSort(name, 'desc', sortType)}>arrow_drop_down</i>
        </div>
      </div>
    </th>
  )
};

export default SortableColumn;
