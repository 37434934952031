import React from 'react';
import UserMenu from './UserMenu.jsx';
import "./navigation.css";
import logo from "./images/logo.png";

export default function Navigation(props) {
  return (
    <div id="navigation" className="eyesee-header">
      <div className="logo">
        <img src={props.appLogo || logo} alt={props.title} />
      </div>

      <ul className="menu">
        {props.links.map((link, i) =>
          <li key={link.id} className="menu-item">
            <i className={`${link.classes} material-icons grey-text link`} onClick={link.onClick} title={link.title} > {link.content} </i>
          </li>
        )}
      </ul>

      {
        <UserMenu user={props.user} appName={props.appName}/>
      }

    </div>
  )
}
