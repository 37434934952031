export const storage = {
  setItem(key, item) {
    let newItem = item;
    if (typeof item === 'object') {
      newItem = JSON.stringify(item);
    }
    localStorage.setItem(key, newItem)
  },

  getItem(item) {
    return localStorage.getItem(item);
  },

  getItemProperty(itemKey, propKey) {
    let item = storage.getItem(itemKey);

    if (!item) return null;

    try {
      item = JSON.parse(item);
      return item[propKey];
    } catch (e) {
      return item;
    }
  },

  removeItem(item) {
    localStorage.removeItem(item);
  },

  clear() {
    localStorage.clear();
  }
}
