import React, {useEffect} from 'react';
import TaskImages from './TaskImages.jsx';
import TaskVideos from './TaskVideos.jsx';
import {g} from '../../helpers/global.js';
import {connect} from 'react-redux';
import {saveProject, fetchProjects} from '../../redux/actions.js';
import {api} from '../../api';

function PTTaskBody ({task, updateTask, updated, project, cell, fetchProjects}) {
  useEffect(() => {}, [updated]);

  let setName = e => {
    if (!g.validateName(e.target.value)) return;
    update('name', e.target.value);
  };

  let update = (key, value) => {
    let updatedTask = g.deepCopy(task);
    updatedTask[key] = value;
    updateTask(updatedTask);
  };

  let uploadMedia = file => {
    let formData = new FormData();
    formData.append('cell', cell.name);
    formData.append('task', task.name);
    formData.append('project_id', project.id);
    formData.append('file', file);
    return api.uploadMedia(formData)
      .then(response => {
        // save the updated version of the project - with video/image path added
        return Promise.resolve(response.data);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  };

  let deleteMedia = file => {
    let arr = [];
    return new Promise((resolve, reject) => {
      return fetchProjects().then(projects => {
        for (let p of projects) {
          if (p.id === project.id) {
            p = project;
          }
          arr.push(p);
        }
        if (g.canDeleteMedia(file.path, arr)) {
          return api.deleteMedia({file: file.path})
            .then(response => {
              // save the updated version of the project - with video/image path added
              return resolve(response.data);
            })
            .catch(err => {
              return reject(err);
            });
        } else {
          return resolve();
        }
      })
    });
  };

  return (
    <div className="collapsible-body">
      <div className="input-field">
        <input type="text" value={task.name} name="name" onChange={setName} disabled={cell.active}/>
        <label className="active">Name</label>
      </div>
      <TaskImages images={task.imgs}
                  disabled={!!cell.active}
                  projectExists={!!project.id}
                  updateImages={images => update('imgs', images)}
                  uploadMedia={uploadMedia}
                  deleteMedia={deleteMedia}/>

      <TaskVideos videos={task.videos}
                  disabled={!!cell.active}
                  projectExists={!!project.id}
                  uploadMedia={uploadMedia}
                  deleteMedia={deleteMedia}
                  updateVideos={videos => update('videos', videos)}
                  isPreloadVideos={task.isPreloadVideos}
                  setPreloadVideos={isPreloadVideos => update('isPreloadVideos', isPreloadVideos)}
                  />
    </div>
  )
}
const mapStateToProps = state => {
  return {
    project: state.project
  }
}

const mapDispatchToProps = dispatch => {
  return {
    saveProject: project => dispatch(saveProject(project, dispatch)),
    fetchProjects: () => dispatch(fetchProjects())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PTTaskBody);
