import React from 'react'
import "./searchable-column.css";

export default function SearchableColumn({ placeholder, onSearch, name }) {
  return (
    <th>
      <div className="input-field searchable">
        <i className="material-icons">search</i>
        <input type="text" placeholder={placeholder} onChange={onSearch} name={name} />
      </div>
    </th>
  )
}
