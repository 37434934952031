import React from 'react';
import PrivateRoute from './PrivateRoute.jsx';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Projects from './pages/Projects.jsx';
import Project from './pages/Project.jsx';
import Tasks from './pages/Tasks.jsx';
import './assets/style/index.scss';

function App() {
  return (
    <div id="app">
      <Router>
        <Switch>
          <Route exact path='/' component={Projects} />
          <PrivateRoute exact path='/projects/:id' component={Project} />
          <PrivateRoute exact path='/projects/:projectID/:cellID/tasks' component={Tasks} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
