import React, { useState } from 'react';
import imgPlaceholder from '../../assets/images/image-placeholder.png';
import { g } from '../../helpers/global.js';
export default function NewImage({ uploadImage, disabled }) {

  let [image, setImage] = useState({
    name: 'stimulus_name',
    path: '',
    duration: 5000,
    id: -1,
    upload: true
  });

  let setName = e => {
    let name = e.target.value;
    if (g.validateName(name))
      setImage({ ...image, name });
  }

  let setDuration = e => {
    let duration = Number(e.target.value) * 1000;
    if (!duration) duration = 5000;
    setImage({ ...image, duration });
  }

  let handleUpload = e => {
    if (!e.target.files) return;
    let imageUpdated = { ...image };
    imageUpdated.file = e.target.files[0];
    imageUpdated.id = Date.now();
    setImage(imageUpdated);
    uploadImage(imageUpdated);
  }

  let updateUpload = e => {
    let imageUpdated = g.deepCopy(image);
    imageUpdated.upload = !image.upload;
    setImage(imageUpdated);
  };

  return (
    <div className="task-addmedia newimg">
      <input type="file" accept=".png, .jpg, .jpeg" onChange={handleUpload} disabled={disabled} />

      <label className="img-upload">
        <input type="checkbox" checked={image.upload} className="filled-in" onChange={updateUpload} />
        <span className="active">Upload</span>
      </label>

      <div className="input-field img-duration">
        <input type="text" value={Math.round(image.duration / 1000)} onChange={setDuration} disabled={disabled} />
        <span className="stimulus-duration">s</span>
        <label className="active">Duration</label>
      </div>


      <div className="input-field">
        <input type="text" value={image.name} onChange={setName} disabled={disabled} />
        <label className="active" value="Name"> Name </label>
      </div>

      <div className="task-previewmedia">
        <img src={imgPlaceholder} alt="task" />
      </div>
    </div>
  )
}
